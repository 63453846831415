import React from "react";

export default function Header() {
    return(
        <p className="App-title sm:text-2xl text-lg">
            Welcome to KinesisGames' Reactor: The Hub for our{" "}
            <a
                className="App-link underline"
                href="https://reactjs.org/"
                target="_blank"
                rel="noopener noreferrer"
            >
            React
            </a>
            {" "}projects.
        </p>
    );
}