import React from 'react';
import logo from './logo.png';

import './App.css';

import Header from "./Header";

function App() {
  const projects = [
    {"id": "todolist-app", "title": "Todolist App"},
    {"id": "db-backend-test", "title": "MongoDB + Express Demo"},
    {"id": "tic-tac-toe", "title": "Tic Tac Toe Game"},
  ];

  const otherProjects = [
    { "link": "https://pass.kinesis.games", "title": "A secure password manager" },
    { "link": "https://chat.kinesis.games", "title": "A live messaging app"},
    { "link": "https://upload.kinesis.games", "title": "For uploading & storing files" }
  ]

  const listItems = otherProjects.map(project => (
    <li className="my-8 py-2">
      <a className="px-16 py-4 bg-gray-800 rounded hover:bg-gray-700" href={project.link}>{project.title}</a>
    </li>
  ));

  projects.map(project => (
    listItems.push(
      <li className="my-8 py-2">
        <a className="px-16 py-4 bg-gray-800 rounded hover:bg-gray-700" href={`./projects/${project.id}/`}>{project.title}</a>
      </li>
    )
  ));

  return (
    <div className="App">
      <header className="App-header w-screen">
        <Header />

        <img src={logo} className="App-logo" alt="logo" />

        <hr />

        <h1 className="projectHeader sm:mt-2 mt-4">Projects:</h1>
        <ul>
          {listItems}
        </ul>

        <hr className="mt-2" />

        <a
          className="App-link px-16 py-4 my-4 bg-gray-800 rounded hover:bg-gray-700"
          href="https://links.kinesis.games"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit our other sites
        </a>
      </header>
    </div>
  );
}

export default App;
